import React from "react";

import { Container, Row, Col, Badge } from "reactstrap";

import preview from './../assets/img/preview.png';

function Intro() {
  return (
    <>
      <section className="section">
        <Container>
          <Row className="row-grid align-items-center">

            {/* Project preview */}
            <Col className="order-md-2" md="6">
              <img
                alt="Game images"
                className="img-fluid floating"
                src={preview}
              />
            </Col>

            {/* Project info */}
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">

                {/* Left top large icon */}
                {/* <div className="icon icon-lg icon-shape icon-shape-mlf-primary shadow rounded-circle mb-5">
                  <i className="fas fa-play"></i>
                </div> */}

                <h3 className="text-mlf-dark"><span className="text-mlf-blue">Your</span> Lucky Farm</h3>

                {/* Principal */}
                <p className="text-mlf-dark">
                  You are in charge!
                  Decide what to plant between the different options
                  on the market, take care of the crops and harvest
                  to make a profit.
                </p>

                <ul className="list-unstyled mt-5">
                  {/* Player decide */}
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="mlf-green-2">
                          <i className="ni ni-settings-gear-65 text-white" />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0 text-mlf-dark">Decide how your farm will work</h6>
                      </div>
                    </div>
                  </li>

                  {/* Fishing */}
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="mlf-green-2">
                          <i className="fas fa-fish text-white" />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0 text-mlf-dark">Bet your luck with fishing</h6>
                      </div>
                    </div>
                  </li>

                  {/* Community */}
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="mlf-green-2">
                          <i className="ni ni-satisfied text-white" />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0 text-mlf-dark">Interact with the community</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Intro;
