import React from "react";

import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="row-grid align-items-center mb-2">
            <Col lg="6">
              <h4 className="mb-0 font-weight-light text-mlf-dark">
                Find us on the following platforms.
              </h4>
            </Col>
            <Col className="text-lg-right btn-wrapper" lg="6">
              <Button
                className="btn-icon-only rounded-circle"
                color="twitter"
                href="https://twitter.com/MyLuckyFarm"
                id="tooltip475038074"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-twitter" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip475038074">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="facebook"
                href="https://www.facebook.com/MyLuckyFarm"
                id="tooltip837440414"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-facebook-square" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip837440414">
                Like us
              </UncontrolledTooltip>

              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="instagram"
                href="https://www.instagram.com/MyLucky.Farm"
                id="tooltip837440414"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-instagram" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip837440414">
                Like us
              </UncontrolledTooltip>

              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="discord"
                href="https://discord.gg/E8UBwDydcg"
                id="tooltip829810202"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-discord" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip829810202">
                Join us
              </UncontrolledTooltip>
            </Col>
          </Row>
          {/* <hr /> */}
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright text-mlf-dark">
                © {new Date().getFullYear()}{" "}
                <a href="#" target="">
                  My Lucky Farm
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
