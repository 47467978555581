import React from "react";

import { Container, Row, Col } from "reactstrap";


function Middle() {
  return (
    <>
      <section className="section bg-gradient-primary">
        <Container>
          {/* Title */}
          <Row className="text-center justify-content-center">
            <Col lg="10">
              <h2 className="display-3 text-mlf-green-3">
                Manage your own farm
              </h2>
              <p className="lead text-mlf-dark">
                Take care of your virtual farm. Grow potatoes, carrots, tomatoes and more.
              </p>
            </Col>
          </Row>

          {/* Separated information */}
          <Row className="row-grid mt-5 text-center">
            {/* Play as you want */}
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="ni ni-ruler-pencil text-primary" />
              </div>
              <h5 className="text-white mt-3">Play as you want</h5>
              <p className="text-white mt-3">
                You decide what to plant
              </p>
            </Col>

            {/* Profit */}
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="fas fa-money-bill-wave text-primary" />
              </div>
              <h5 className="text-white mt-3">Make profit</h5>
              <p className="text-white mt-3">
                Harvest and sell crops to make profit
              </p>
            </Col>

            {/* Expand */}
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="fas fa-expand-arrows-alt text-primary" />
              </div>
              <h5 className="text-white mt-3">Expand</h5>
              <p className="text-white mt-3">
                Unlock plots and plant more crops
              </p>
            </Col>
          </Row>
          <br />
        </Container>
      </section>
    </>
  );
}

export default Middle;
