import React from "react";

import { Button, Container, Row, Col } from "reactstrap";

import logo from './../assets/img/logo.png';
import Separator from "./util/Separator";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="position-relative">
          <section className="section section-hero section-shaped">
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    {/* Logo and text */}
                    <img alt="My Lucky Farm Logo" className="img-fluid" src={logo} style={{ width: "500px" }} />
                    <p className="lead text-white">Your virtual farm on the BSC network</p>

                    {/* Buttons */}
                    <div className="btn-wrapper mt-5">
                      {/* Play Button */}
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0"
                        color="default"
                        // disabled
                        href="/game"
                        size="md"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-gamepad" />
                        </span>
                        <span className="btn-inner--text">Play Now</span>
                      </Button>

                      {/* PancakeSwap Button */}
                      <Button
                        className="btn-icon mb-3 mb-sm-0"
                        color="mlf-dark"
                        disabled
                        href="https://pancakeswap.finance/"
                        size="md"
                        target="_blank"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="far fa-money-bill-alt" />
                        </span>
                        <span className="btn-inner--text">
                          <span className="text-warning mr-1">Buy on</span>PancakeSwap
                        </span>
                      </Button>
                    </div>

                    <div className="btn-wrapper mt-4">
                      <Button
                        type="button"
                        outline
                        color="mlf-dark"
                        // disabled
                        href="/presale"
                        size="sm"
                      >
                        <span className="btn-inner--text">
                          Join the presale event
                        </span>
                      </Button>
                    </div>

                    {/* Token Contract text */}
                    {/* <div className="mt-4">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        Token Contract:<br/><span className="text-mlf-dark">0x000000000000000000000000000000</span>
                      </small>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Container>
            <Separator />
          </section>
        </div>
      </>
    );
  }
}

export default Header;
