import React from "react";

// Sections
import Header from "../components/Header.js";
import Intro from "../components/Intro.js";
import Middle from "../components/Middle.js"
// import Team from "../components/Team.js"
import Enjoyed from "../components/Enjoyed"
import PoweredBy from "../components/PoweredBy"
import Footer from "../components/Footer.js";


function Index() {
  return (
    <>
      <main>
        {/* Website header with logo and buttons to play the game and to the token contract. */}
        <Header />

        {/* First info and game images */}
        <Intro />

        {/* Section with more information about the project */}
        <Middle />

        {/* Team information */}
        {/* <Team /> */}

        {/* Technologies used in the project */}
        <PoweredBy />

        {/* User feeling section */}
        <Enjoyed />

      </main>

      {/* Website footer */}
      <Footer />
    </>
  );
}

export default Index;
