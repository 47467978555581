import React from "react";

import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";

import binance from './../assets/img/logos/binance.png';
import moralis from './../assets/img/logos/moralis_dark.png';
import unity from './../assets/img/logos/unity.png';

function PoweredBy(params) {
  return (
    <>
      <section className="section">
        <Container>
          <div className="text-center">
            <h4 className="display-3 text-mlf-dark">Powered by</h4>
            <br />
            <Row className="justify-content-center">
              {/* Moralis */}
              <Col lg="2" xs="3">
                <a href="moralis" id="tooltipMoralis" target="_blank">
                  <img alt="Moralis Logo" className="img-fluid" src={moralis} style={{ width: "75px", borderRadius: "50%" }} />
                </a>
                <UncontrolledTooltip delay={0} target="tooltipMoralis">
                  Moralis<br />Web development platform to deploy dApps
                </UncontrolledTooltip>
              </Col>

              {/* Binance */}
              <Col lg="2" xs="3">
                <a href="binance" id="tooltipBinance" target="_blank">
                  <img alt="Binance Logo" className="img-fluid" src={binance} style={{ width: "75px", borderRadius: "50%" }} />
                </a>
                <UncontrolledTooltip delay={0} target="tooltipBinance">
                  Binance Smart Chain<br />Smart contract-enabled blockchain network
                </UncontrolledTooltip>
              </Col>

              {/* Unity */}
              <Col lg="2" xs="3">
                <a href="unity" id="tooltipUnity" target="_blank">
                  <img alt="Unity Logo" className="img-fluid" src={unity} style={{ width: "75px", borderRadius: "50%" }} />
                </a>
                <UncontrolledTooltip delay={0} target="tooltipUnity">
                  Unity<br />Real-time development platform for building 2D and 3D applications
                </UncontrolledTooltip>
              </Col>
            </Row>
          </div>
          <br />
        </Container>
      </section>
    </>
  );
}

export default PoweredBy;
