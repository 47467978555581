import React from "react";

import { Container, Row, Col, Button } from "reactstrap";


function Enjoyed() {
  return (
    <>
      <section className="section section-hero section-shaped">
        {/* Background Squares */}
        <div className="shape shape-style-1 shape-primary">
          <span className="span-150" />
          <span className="span-50" />
          <span className="span-50" />
          <span className="span-75" />
          <span className="span-100" />
          <span className="span-75" />
          <span className="span-50" />
          <span className="span-100" />
        </div>

        <Container>
          <Row className="row-grid justify-content-center">
            <Col className="text-center" lg="12">
              <br /><br />
              <h2 className="display-3 text-mlf-dark">
                Did you like this
                <span className="text-mlf-orange font-weight-bold">Promising New Project?</span>
              </h2>
              <p className="lead text-dark">
                If the answer is yes, feel free to buy our token on PancakeSwap using the link below
              </p>

              {/* PancakeSwap Button */}
              <div className="btn-wrapper">
                <Button
                  className="mb-3 mb-sm-0"
                  color="secondary"
                  href="#"
                  disabled
                >
                  Buy on PancakeSwap
                </Button>
              </div>
              <br /><br />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Enjoyed;