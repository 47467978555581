import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MoralisProvider } from "react-moralis";

import Index from "./views/Index";
// import Presale from "./views/Presale";
// import Game from "./views/Game";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

// Moralis Testnet Server
const appId = "6tGJ5sLQjj6ucd8y68fGp3vDZbDL5r9CC9FvGPRX";
const serverUrl = "https://pnufxz3vpnin.usemoralis.com:2053/server";

render(
  <BrowserRouter>
    <Routes>
      {/* Index */}
      <Route path="/" exact element={<Index />} />

      {/* Presale */}
      {/* <Route path="/presale" exact element={
        <MoralisProvider appId={appId} serverUrl={serverUrl}>
          <Presale />
        </MoralisProvider>
      } /> */}
      
      {/* Game */}
      {/* <Route path="/game" exact element={
        <MoralisProvider appId={appId} serverUrl={serverUrl}>
          <Game />
        </MoralisProvider>
      }/>     */}
      
      {/* Profile */}
      {/* <Route path="/game/u/profile" exact element={<sProfile />} /> */}
      
      {/* Others */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);